export const en = {
	footer_desc:
		"Education Management system helps students to learn efficiently ,easily and productively .",
	about: "About",
	links: "Links",
	support: "Support",
	subscribe: "Subscribe our newsletter",
};

export const ar = {
	footer_desc:
		"اقوي دورة قدرات معتمدة على مستوى المملكة العربيه السعوديه 	تهدف  منصه انجح  للتدريب إلى تسهيل العملية التعليمية للطالب وتقديمها بطريقة حديثه تضمن سرعة الوصول للمحتوى التعليمي وشرحه في فيديوهات بطريقة مبسطه   على يد مدربين اكفاء لا تفكر كثيراً ولا  تضيع  وقتك في البحث وتبحث أكثر فقط بمنصتنا انجح  نضع بين يديك  كل ما يهمك في القدرات ونقربه إليك حيث تصبح العملية التعليمية أسهل من خلالنا و تدريبك علي حل  اكثر من 100 اختبارا الكتروني وتنميه مهاراتك انجح اول منصع كل ما يهمها هو مصلحه الطالب ودعمه حتي النجاح توفر لك الرد علي كل تساولاتك  فلن تحتاج إلا لثوانٍ معدودة لتجد في منصه انجح كل  ما تبحث عنه.. لا غنى لك عن منصه انجح  للتدريب لأن العلم والتدريب معنا أسهل. انجح معا للامتياز",
	about: "معلومات عنا",
	links: "روابط",
	support: "الدعم",
	subscribe: "اشترك معنا",
};
