import Users from "../user";
import { createReducer } from "@reduxjs/toolkit";

const initialState: { token?: any } = {
  token: null,
};

const tokenReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Users.thunks.doLogout.fulfilled, (state) => {
      state.token = null;
    })
    .addCase(Users.thunks.doRefresh.rejected, (state) => {
      state.token = null;
    })
    .addCase(Users.thunks.doRefresh.fulfilled, (state, action) => {
      state.token = action.payload.data.data.authorisation.token;
    })
    .addCase(Users.thunks.doLogIn.fulfilled, (state, action) => {
      state.token = action.payload.data.data.authorisation.token;
    })
    .addCase(Users.thunks.doRegister.fulfilled, (state, action) => {
      state.token = action.payload.data.data.authorisation.token;
    });
});

export default tokenReducer;
