import axios from "axios";

const getLevels = () => axios.get(`/api/levels`);
const getClasses = () => axios.get("/api/classes");
const constantsApi = {
  getLevels,
  getClasses,
};

export default constantsApi;
