import { ar as basic } from "./namespaces/basic";
import { ar as Footer } from "./namespaces/Footer";
import { ar as Home } from "./namespaces/Home";

// Injaah
import { ar as InjaahHome } from "./namespaces/injaah/Home";
import { ar as InjaahBasic } from "./namespaces/injaah/Basic";
import { ar as InjaahProfile } from "./namespaces/injaah/Profile";

// eslint-disable-next-line
export default {
	basic,
	Footer,
	Home,
	InjaahHome,
	InjaahBasic,
	InjaahProfile,
};
