import { en as basic } from "./namespaces/basic";
import { en as Footer } from "./namespaces/Footer";
import { en as Home } from "./namespaces/Home";

// Injaah
import { en as InjaahHome } from "./namespaces/injaah/Home";
import { en as InjaahBasic } from "./namespaces/injaah/Basic";
import { en as InjaahProfile } from "./namespaces/injaah/Profile";

// eslint-disable-next-line
export default {
	basic,
	Footer,
	Home,
	InjaahHome,
	InjaahBasic,
	InjaahProfile,
};
