import cloudRooms from "./API";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const doGetCloudRooms = createAsyncThunk<any, any, any>(
  "cloudRooms/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await cloudRooms.getAllCloudRooms();
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doGetSingleCloudRoom = createAsyncThunk<any, any, any>(
  "cloudRooms/singleCloudRoomFetch",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await cloudRooms.getSingleCLoudRoom(slug);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doGetSingleSession = createAsyncThunk<any, any, any>(
  "sessions/singleSessionFetch",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await cloudRooms.getSingleSession(slug);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const thunks = {
  doGetCloudRooms,
  doGetSingleCloudRoom,
  doGetSingleSession,
};
export default thunks;
