import { createAsyncThunk } from "@reduxjs/toolkit";
import QuizApi from "./API";

export const doGetQuiz = createAsyncThunk<
  any,
  { courseSlug: string; quizSlug: string },
  any
>("quiz/fetchSingle", async (data, { rejectWithValue }) => {
  try {
    const response = await QuizApi.getSingleQuiz(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const doGetQuizCR = createAsyncThunk<
  any,
  { sessionSlug: string; quizId: any },
  any
>("quiz/fetchSingle_cr", async (data, { rejectWithValue }) => {
  try {
    const response = await QuizApi.getSingleQuizCR(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const doSendAttemp = createAsyncThunk<
  any,
  { courseSlug: string; quizSlug: string; answer: any; id?: any },
  any
>("quiz/postAttemp", async (data, { rejectWithValue }) => {
  try {
    const response = await QuizApi.sendQuizAttemp(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const doSendAttemp_CR = createAsyncThunk<
  any,
  { sessionSlug: string; quizId: any; answer: any; id?: any },
  any
>("quiz/postAttemp_cr", async (data, { rejectWithValue }) => {
  try {
    const response = await QuizApi.sendQuizAttempCR(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const thunks = { doGetQuiz, doSendAttemp, doGetQuizCR, doSendAttemp_CR };

export default thunks;
