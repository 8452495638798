import { EntityKeys } from "../schema";
import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import { RootState } from "../store";
import { toast } from "react-toastify";
import User from "redux/user";
import { TCategory, TContent, TCourses, TQA } from "./model";

type TInitialValues = {
	courses?: TCourses[];
	current_page?: number;
	searchDetails: {
		current_page?: number;
		total_count?: number;
		last_page?: number;
		searchedCourses?: TCourses[];
	};
	qADetails: {
		current_page?: number;
		from?: number;
		last_page?: number;
		path?: string;
		per_page?: number;
		to?: number;
		total?: number;
	};
	qA?: TQA[];
	newQA: TQA[];
	home_data?: {
		new_courses?: TCourses[];
		featured_courses?: TCourses[];
		popular_courses?: TCourses[];
		categories?: TCategory[];
	};
	selectedCourse?: TCourses;
	sections?: any;
	contents?: TContent[];
	enrolledCoursesData: any;
};

export const CoursesInitialValues: TInitialValues = {
	courses: [],
	searchDetails: {
		current_page: 1,
		total_count: 0,
		last_page: 0,
		searchedCourses: [],
	},
	home_data: {
		new_courses: [],
		featured_courses: [],
		popular_courses: [],
		categories: [],
	},
	selectedCourse: undefined,
	sections: {},
	qA: [],
	newQA: [],
	qADetails: {},
	contents: [],
	enrolledCoursesData: {},
};

const slice = createSlice({
	name: "courses",
	initialState: CoursesInitialValues,
	reducers: {
		setEnrolledCourseData: (state, action) => {
			if (!state.enrolledCoursesData) {
				state.enrolledCoursesData = {};
			}
			state.enrolledCoursesData[action.payload.slug] = {
				list: action.payload.list,
				type: action.payload.type,
				id: action.payload.id,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.doGetCourses.fulfilled, (state, action) => {
			state.courses = action.payload.data.data["courses "]?.data;
			// state.current_page = action.payload.data.data.courses.current_page;
		});
		builder.addCase(thunks.doGetCourses.rejected, (state, action) => {
			console.log(action);
		});
		builder.addCase(User.thunks.doRefresh.rejected, (state, action) => {});
		builder.addCase(Course.thunks.doGetHome.rejected, (state, action) => {});
		builder.addCase(Course.thunks.doGetHome.fulfilled, (state, action) => {
			state.home_data = action.payload.data.data;
		});
		builder.addCase(thunks.doGetSearchCourses.fulfilled, (state, action) => {
			if (action?.payload?.data?.success === false) {
				toast.error("No courses were found");
				state.searchDetails.searchedCourses = [];
				state.searchDetails.total_count = 0;
				state.searchDetails.last_page = 0;
				return;
			}
			state.searchDetails.last_page =
				action.payload.data.data["courses "].meta.last_page;
			state.searchDetails.current_page =
				action.payload.data.data["courses "].meta.current_page;
			state.searchDetails.total_count =
				action.payload.data.data["courses "].meta.total;
			state.searchDetails.searchedCourses =
				action.payload.data.data["courses "].data;
		});
		builder.addCase(thunks.doGetSearchCourses.rejected, (state, action) => {
			console.log(action);
		});
		builder.addCase(thunks.doGetSingleCourse.fulfilled, (state, action) => {
			state.selectedCourse = action.payload;
		});
		builder.addCase(thunks.doGetSingleCourse.rejected, (state, action) => {
			console.log(action);
		});
		builder.addCase(thunks.doGetCourseContent.fulfilled, (state, action) => {
			console.log(action.meta.arg.slug);
			if (!state.sections[action?.meta?.arg?.slug]) {
				state.sections[action?.meta?.arg?.slug] = {};
			}
			state.sections[action?.meta?.arg?.slug][action?.meta?.arg?.id] =
				action.payload?.data;
		});
		builder.addCase(thunks.doPostReview.fulfilled, (state, action) => {
			console.log(action);
		});
		builder.addCase(thunks.doPostReview.rejected, (state, action) => {
			toast.error("You can't add more than one review to the same course");
		});
		builder.addCase(thunks.doGetQA.fulfilled, (state, action) => {
			state.qA = action.payload.data.data;
			state.qADetails = action.payload.data.meta;
			console.log(state.qA);
		});
		builder.addCase(thunks.doGetQA.rejected, (state, action) => {
			console.log(action);
		});
		builder.addCase(thunks.doGetNewQA.fulfilled, (state, action) => {
			if (action?.payload?.success === false) {
				toast.error("No courses were found");
			}
			state.qA = action.payload.data.data;
		});
		builder.addCase(thunks.doGetNewQA.rejected, (state, action) => {
			console.log(action);
		});
		builder.addCase(thunks.doAddQuestion.fulfilled, (state, action) => {
			console.log(action);
		});
		builder.addCase(thunks.doAddQuestion.rejected, (state, action) => {
			console.log(action);
		});
		builder.addCase(thunks.doGetContents.fulfilled, (state, action) => {
			state.contents = action.payload.data;
		});
		builder.addCase(thunks.doGetContents.rejected, (state, action) => {
			toast.error("someThing went wrong");
		});
		// builder.addCase(User.thunks.doRefresh.rejected, (state) => {
		//   state = initialValues;
		// });
		// builder.addCase(User.thunks.doLogout.rejected, (state) => {
		//   state = initialValues;
		// });
	},
});

const Course = {
	thunks,
	slice,
	setEnrolledCourseData: slice.actions.setEnrolledCourseData,
};

export const selectAllCourses = (state: RootState) => state.courses.courses;
export const selectHomeData = (state: RootState) => state.courses.home_data;
export const selectSelectedCourse = (state: RootState) =>
	state.courses.selectedCourse;
export const selectSearchDetails = (state: RootState) =>
	state.courses.searchDetails;
export const selectSections = (state: RootState) => state.courses.sections;
export const selectQA = (state: RootState) => state.courses.qA;
export const selectContent = (state: RootState) => state.courses.contents;
export const selectEnrolledCourseData = (state: RootState) =>
	state.courses.enrolledCoursesData;
export default Course;
