import { combineReducers } from "@reduxjs/toolkit";
import Cart from "./Cart";
import CloudRoom from "./cloudRooms";
import Constant from "./constants";
import Course from "./courses";
import Profile from "./profile";
import Quizes from "./quizes";
import tokenReducer from "./tokens/reducer";
import User from "./user";
import loadingSlice from "./_loading";

const users = User.slice.reducer;
const tokens = tokenReducer;
const _loading = loadingSlice.reducer;
const profiles = Profile.slice.reducer;
const courses = Course.slice.reducer;
const constants = Constant.slice.reducer;
const cloudRooms = CloudRoom.slice.reducer;
const quizes = Quizes.slice.reducer;
const cart = Cart.slice.reducer;
const combinedReducer = combineReducers({
  _loading,
  tokens,
  users,
  profiles,
  courses,
  constants,
  cloudRooms,
  quizes,
  cart,
});

export default combinedReducer;
