import { EntityKeys } from "../schema";
import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import { RootState } from "../store";
import { TClass, TLevel } from "./model";

type TInitialValues = {
  levels?: TLevel[];
  classes?: TClass[];
};

const initialValues: TInitialValues = {
  levels: [],
  classes: [],
};

const slice = createSlice({
  name: "constants",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetLevels.fulfilled, (state, action) => {
      state.levels = action.payload.data;
    });
    builder.addCase(thunks.doGetClasses.fulfilled, (state, action) => {
      state.classes = action.payload.data;
    });
  },
});

const Constant = {
  thunks,
  slice,
};

export const selectLevels = (state: RootState) => state.constants.levels;
export const selectClasses = (state: RootState) => state.constants.classes;

export default Constant;
