import axios from "axios";

const getCourses = () => axios.get(`/api/courses`);
const getHome = () => axios.get("/api/home/main_courses");
const getSearchCorses = async ({
  search,
  categories,
  levels,
  classes,
  price_min,
  price_max,
  page,
}: any) => {
  let priceParams;

  if (price_min && price_max) {
    priceParams = `&price_min=${price_min}&price_max=${price_max}`;
  }
  return axios.get(
    `/api/courses?q=${search || ""}${
      categories && categories.length > 0
        ? categories
            .map((item: any) => `&category[]=${item}`)
            .toString()
            .replace(/,/g, "")
        : ""
    }${
      levels && levels.length > 0
        ? levels
            .map((item: any) => `&level[]=${item}`)
            .toString()
            .replace(/,/g, "")
        : ""
    }${
      classes && classes.length > 0
        ? classes
            .map((item: any) => `&class[]=${item}`)
            .toString()
            .replace(/,/g, "")
        : ""
    }${priceParams || ""}&page=${page || 1}`
  );
};
const getSingleCourse = (slug: any) => axios.get(`/api/courses/${slug}`);
const getCourseContent = (slug: any, id: any) =>
  axios.get(`/api/courses/${slug}/sections/${id}`);
const addReview = (slug: any, review: any) =>
  axios.post(`/api/courses/${slug}/reviews`, review);
const getQA = (slug: any) =>
  axios.get(`api/courses/${slug}/questions-and-answers`);
const getNewQA = (slug: any, qA: any) =>
  axios.get(
    `api/courses/${slug}/questions-and-answers?q=${qA.q}&sort=${
      qA.sort
    }&filter=${qA.filter}&count=${3}`
  );
const addQuestion = (slug: any, qData: any) =>
  axios.post(`api/courses/${slug}/questions-and-answers`, qData);
const getContents = (slug: any) => axios.get(`/api/courses/${slug}/contents`);
const CoursesApi = {
  getCourses,
  getHome,
  getSearchCorses,
  getSingleCourse,
  getCourseContent,
  addReview,
  getQA,
  getNewQA,
  addQuestion,
  getContents,
};

export default CoursesApi;
