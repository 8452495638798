// ESSENTIALS
// import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { lazy, Suspense } from "react";
// ROUTES
import AllRoutes from "./routes";

// COMPONENTS
import ColorSwitcher from "components/UI/ColorSwitcher/ColorSwitcher";

// import i18n (needs to be bundled ;))
import "./utils/i18n";

// STYLES
import "./App.scss";
//REDUX
import { useAppDispatch } from "redux/store";
import { useMemo } from "react";
import User, { selectCurrentUser } from "redux/user";
import Profile from "redux/profile";
import Course, { selectSelectedCourse } from "redux/courses";
import Constant from "redux/constants";
import { useLoadingSelector } from "redux/selectors";
import GifLoader from "react-gif-loader";
import CloudRoom from "redux/cloudRooms";
import Quizes from "redux/quizes";

function App() {
	const dispatch = useAppDispatch();
	const isLoading = useLoadingSelector(Course.thunks.doGetSingleCourse);
	const isQuizLoading = useLoadingSelector(Quizes.thunks.doGetQuiz);
	const isRoomLoading = useLoadingSelector(
		CloudRoom.thunks.doGetSingleCloudRoom
	);
	const isSubmittingAQuizLoading = useLoadingSelector(
		Quizes.thunks.doSendAttemp
	);
	useMemo(() => {
		Promise.all([
			dispatch(User.thunks.doRefresh({})),
			dispatch(Profile.thunks.doGetProfile({})),
			// dispatch(Course.thunks.doGetCourses({})),
			// dispatch(Course.thunks.doGetHome({})),
			// dispatch(Constant.thunks.doGetLevels({})),
			// dispatch(Constant.thunks.doGetClasses({})),
			// dispatch(CloudRoom.thunks.doGetCloudRooms({})),
		]);
	}, []);

	const AllRoutes = lazy(() => import("./routes"));
	
	return (
		<div className="App">
			<div
				className="gif_loader"
				style={{
					display:
						isLoading ||
						isQuizLoading ||
						isSubmittingAQuizLoading ||
						isRoomLoading
							? "block"
							: "none",
				}}
			>
				<GifLoader
					loading={
						isLoading ||
						isQuizLoading ||
						isSubmittingAQuizLoading ||
						isRoomLoading
					}
					imageSrc="/img/loading.gif"
					imageStyle={{ marginTop: "0", paddingTop: "35vh" }}
					overlayBackground="#fff"
				/>
			</div>
			<Suspense
				fallback={
					<GifLoader
						loading={true}
						imageSrc="/img/loading.gif"
						imageStyle={{ marginTop: "0", paddingTop: "35vh" }}
						overlayBackground="#fff"
					/>
				}
			>
				{/* <ColorSwitcher /> */}
				<AllRoutes />
			</Suspense>
			<ToastContainer />
		</div>
	);
}

export default App;
