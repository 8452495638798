import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import { RootState } from "../store";
import { TCart } from "./model";
import { toast } from "react-toastify";

type TInitialValues = {
  cart: TCart;
};

const initialValues: TInitialValues = {
  cart: {},
};

const slice = createSlice({
  name: "cart",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetCartItems.fulfilled, (state, action) => {});
    //Add to cart
    builder.addCase(thunks.doAddToCart.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doAddToCart.rejected, (state, action) => {
      console.log(action);
      // toast("Item  already in cart");
    });
    // //Pay with code
    builder.addCase(thunks.doPayWithCode.fulfilled, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doPayWithCode.rejected, (state, action) => {
      console.log(action);
      toast.error("Should have all items in the cart from a single seller");
    });
  },
});

const Cart = {
  thunks,
  slice,
};

export const selectSelectedQuiz = (state: RootState) => state.cart.cart;
export default Cart;
