import axios from "axios";

const getSingleQuiz = ({
  courseSlug,
  quizSlug,
}: {
  courseSlug: string;
  quizSlug: string;
}) => axios.get(`/api/courses/${courseSlug}/quizzes/${quizSlug}`);

const getSingleQuizCR = ({ sessionSlug, quizId }: any) =>
  axios.get(`/api/sessions/${sessionSlug}/quizzes/${quizId}`);

const sendQuizAttemp = ({
  courseSlug,
  quizSlug,
  answer,
}: {
  courseSlug: string;
  quizSlug: string;
  answer: any;
}) => axios.post(`/api/courses/${courseSlug}/quizzes/${quizSlug}`, answer);

const sendQuizAttempCR = ({
  sessionSlug,
  quizId,
  answer,
}: {
  sessionSlug: string;
  quizId: string;
  answer: any;
}) =>
  axios.post(`/api/sessions/${sessionSlug}/quizzes/${quizId}/attempt`, answer);

const QuizApi = {
  getSingleQuiz,
  sendQuizAttemp,
  getSingleQuizCR,
  sendQuizAttempCR,
};

export default QuizApi;
