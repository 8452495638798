export const en = {
	Account_Settings: "Account Settings",
	Logout: "Logout",
	Change_Password: "Change Password",
	My_Account_Settings: "My Account Settings",
	Edit_Account_Settings: "Edit your account settings here.",
	Password: "Password",
	Change_Account_Password: "Change your account password",
	Current_Password: "Current Password",
	New_Password: "New Password",
	Password_Length: "Password must be 8 or more characters long.",
	Confirm_New_Password: "Confirm New Password",
	Save_Changes: "Save Changes",
	Upload_Photo: "Upload Photo",
	Pictures_Help: "Pictures help your teammates identify you",
	Export_Profile: "Export Profile",
	Print_Download:"Print / Download"
};


export const ar = {
	Account_Settings: "إعدادت الحساب",
	Logout: "تسجيل خروج",
	Profile:"الملف الشخصي",
	Print_Download:"استخراج الملف الشخصي",
	Change_Password: "تعديل كلمة المرور",
	My_Account_Settings: "إعدادات حسابي",
	Edit_Account_Settings: "قم بتحرير إعدادات حسابك الخاصة بك هنا.",
	Password: "كلمة المرور",
	Change_Account_Password: "قم بتغيير كلمة المرور الخاصة بحسابك",
	Current_Password: "كلمة السر الحالية",
	New_Password: "كلمة المرور الجديدة",
	Password_Length: "يجب أن تتكون كلمة المرور من 8 أحرف أو أكثر.",
	Confirm_New_Password: "تأكيد كلمة المرور الجديدة",
	Save_Changes: "حفظ التغييرات",
	Upload_Photo: "رفع صورتك",
	Pictures_Help: "تساعد الصور زملائك في الفريق في التعرف عليك",
	Export_Profile: "استخراج الملف الشخصي",
};
