import { createAsyncThunk } from "@reduxjs/toolkit";
import CoursesApi from "./API";

export const doGetCourses = createAsyncThunk<any, any, any>(
  "courses/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getCourses();
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetHome = createAsyncThunk<any, any, any>(
  "courses/home_fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getHome();
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetSearchCourses = createAsyncThunk<any, any, any>(
  "courses/search",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getSearchCorses(data);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetSingleCourse = createAsyncThunk<any, any, any>(
  "courses/singleCourseFetch",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getSingleCourse(slug);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doGetCourseContent = createAsyncThunk<any, any, any>(
  "courses/course_content",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getCourseContent(data.slug, data.id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doPostReview = createAsyncThunk<any, any, any>(
  "courses/addReview",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.addReview(data.slug, data.review);
      return { data: response };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doGetQA = createAsyncThunk<any, any, any>(
  "courses/QA",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getQA(slug);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doGetNewQA = createAsyncThunk<any, any, any>(
  "courses/NewQA",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getNewQA(data.slug, data.qA);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doAddQuestion = createAsyncThunk<any, any, any>(
  "courses/addQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.addQuestion(data.slug, data.qData);
      return { data: response };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doGetContents = createAsyncThunk<any, any, any>(
  "courses/content",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await CoursesApi.getContents(slug);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const thunks = {
  doGetCourses,
  doGetHome,
  doGetSearchCourses,
  doGetSingleCourse,
  doGetCourseContent,
  doPostReview,
  doGetQA,
  doGetNewQA,
  doAddQuestion,
  doGetContents,
};

export default thunks;
