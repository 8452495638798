export const en = {
	learn_from: "Learn from",
	expert_professionals: "expert professionals",
	join_largest_online: "and join the largest online",
	community: "community",
	what_do_you_want: "What do you want to learn?",
	categories: "Categories",
	New_features: "New features",
	courses_provided_as: "Main Courses Provided as",
	packages: "Packages",
};

export const ar = {
	learn_from: "تعلم من",
	expert_professionals: "الخبراء المحترفين",
	join_largest_online: "وانضم إلى أكبر مجتمع",
	community: "على الإنترنت",
	what_do_you_want: "ماذا تريد أن تتعلم؟",
	categories: "التصنيفات",
	New_features: "الميزات الجديدة",
	courses_provided_as: "الدورات الرئيسية المقدمة على شكل",
	packages: "حزم",
};
