import { createAsyncThunk } from "@reduxjs/toolkit";
import CartApi from "./API";

export const doGetCartItems = createAsyncThunk<any, any, any>(
  "cart/fetchItems",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CartApi.getCartItems();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doAddToCart = createAsyncThunk<any, any, any>(
  "cart/add_toCart",
  async (buyable_id, { rejectWithValue }) => {
    try {
      const response = await CartApi.addToCart(buyable_id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doPayWithCode = createAsyncThunk<any, any, any>(
  "cart/pay_withCode",
  async (code, { rejectWithValue }) => {
    try {
      const response = await CartApi.payWithCode(code);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const thunks = { doGetCartItems, doPayWithCode,doAddToCart };

export default thunks;
