import axios from "../../pages/Injaah/lib/axios";
import { api } from "redux/_axios";

const logIn = (data: any) => axios.post("/login", data);
const register = (data: any) => axios.post("/register", data);
const logout = () => axios.post("/logout");
const refresh = () => axios.post("/refresh");
const forgotPasswordReq = (data: any) =>
  axios.post("/forgot-password", data);
const resetPassword = ({ token, data }: any) =>
  axios.post(`/forgot-password/reset/${token}`, data);
const verifyEmail = ({ verify_id, token_hash_sent_by_mail }: any) =>
  axios.get(`/email/verify/${verify_id}/${token_hash_sent_by_mail}`);

const UserAPI = {
  logIn,
  register,
  verifyEmail,
  logout,
  refresh,
  forgotPasswordReq,
  resetPassword,
};

export default UserAPI;
