import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "utils/i18n";
import reportWebVitals from "./reportWebVitals";

//redux
import { Provider } from "react-redux";
import { store } from "redux/store";

import { ThemeProvider } from "context/ThemeContext";
import { ReactQueryProvider } from "../src/pages/Injaah/lib/react-query";
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "react-query/devtools";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<BrowserRouter>
	<ReactQueryProvider>
		<Provider store={store}>
			<ThemeProvider>
				<App />
				<Toaster />
				{/* <ReactQueryDevtools initialIsOpen={false} /> */}
			</ThemeProvider>
		</Provider>
	</ReactQueryProvider>
	</BrowserRouter>
	// {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
