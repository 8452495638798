import axios, { AxiosRequestConfig } from "axios";
import { TStore } from "./store";
import assign from "lodash/assign";
import { create } from "apisauce";


export const api = create({
  baseURL: process.env.REACT_APP_BASE_DEV_URL,
  headers: { "Content-Type": "application/json" },
});

const initAxios = (store: TStore) => {
  api.axiosInstance.interceptors.request.use((config) => {
    const tokens = store.getState().tokens;
    const authorization = `Bearer ${tokens.token}`;
    const authHeaders = { authorization };
    assign(config.headers, authHeaders);
    return config;
  });

  const injectAuthHeaders = (config: AxiosRequestConfig) => {
    const tokens = store.getState().tokens;
    const authorization = `Bearer ${tokens.token}`;
    const authHeaders = { authorization };
    assign(config.headers, authHeaders);
    return config;
  };

  const handleFormData = (config: AxiosRequestConfig) => {
    if (config.data instanceof FormData) {
      assign(config.headers, {
        "Content-Type": "multipart/form-data",
      });
    }
    return config;
  };

  axios.defaults.baseURL = process.env.REACT_APP_BASE_DEV_URL;
  axios.interceptors.request.use((config) => {
    const tokens = store.getState().tokens;
    const authorization = `Bearer ${tokens.token}`;
    const authHeaders = { authorization };
    assign(config.headers, authHeaders);
    return config;
});

  axios.interceptors.request.use( (config) => {
    if (config.data instanceof FormData) {
      assign(config.headers, {
        "Content-Type": "multipart/form-data",
      });
    }
    return config;
  });
};

export default initAxios;
