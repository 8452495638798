import {
	QueryClient,
	QueryClientProvider,
  } from 'react-query'
import toast from 'react-hot-toast';
import { ReactQueryDevtools } from "react-query/devtools";



// This code is used to globally handle any errors that occur when a query or mutation is run. 
// This is done by calling the toastGlobalErrors function which displays an error message to the user.
// The function is then called within the queryClient variable.

// const toastGlobalErrors = (err) => err.response?.data?.message ?
//                                     toast.error(err.response.data.message) 
//                                     : toast.error(err.message)

// const queryClient = new QueryClient({
// 	defaultOptions: {
// 	queries: {
// 	  onError: (err) => toastGlobalErrors(err),
//       refetchOnWindowFocus: false,
// 	},
// 	mutations: {
// 	    onError: (err) => toastGlobalErrors(err)},
        
// 	},
//   })
const queryClient = new QueryClient();

export const ReactQueryProvider = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
    )
}