import axios from "axios";

import Cookies from "js-cookie";

const getTokenFromLocalStorage = localStorage.getItem("user")
	? JSON.parse(localStorage.getItem("user"))
	: null;

// This is the base API URL
// This is the token that is used to authenticate the user
// This is the header that is used to authenticate the user

// axios.defaults.withCredentials = true;

const axClient = axios.create({
	baseURL: "https://services.injaah.com/api",
	// baseURL: "http://127.0.0.1:8000/api",
	headers: {
		"Content-Type": "application/json",
		// "Access-Control-Allow-Origin": '*',
		Accept: "application/json",
		// 'Authorization': `Bearer ${
		//     getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
		//   }`,
	},
});
axClient.interceptors.request.use((config) => {
	// use config.params if it has been set
	const injah_token = Cookies.get("login_injaah");
	// config.params = config.params || {};
	// add any client instance specific params to config
	// config.params["session_id"] = front_session_id;

	if(injah_token){
		config.headers.Authorization = `Bearer ${injah_token}`;

	}

	return config;
});
export default axClient;
