import axios from "axios";

const getAllCloudRooms = () => axios.get("/api/cloud-rooms");
const getSingleCLoudRoom = (slug: any) => axios.get(`/api/cloud-rooms/${slug}`);
const getSingleSession = ({ sessionSlug, cloudRoomSlug }: any) =>
  axios.get(`/api/sessions/${sessionSlug}`);

const cloudRooms = {
  getAllCloudRooms,
  getSingleCLoudRoom,
  getSingleSession
};
export default cloudRooms;
