import { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext({ color: "#14a800", undefined });

export const ThemeProvider = ({ children }) => {
	const currentColor = localStorage.getItem("color") || "#14a800";

	// localstorage to save the theme choice
	const [theme, setTheme] = useState(currentColor);

	localStorage.setItem("color", theme);

	useEffect(() => {
		var store = document.querySelector(":root");
		store.style.setProperty("--green", theme);
	}, [theme]);

	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};
