import { EntityKeys } from "../schema";
import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import { RootState } from "../store";
import { toast } from "react-toastify";
import { TQuiz } from "./model";

type TInitialValues = {
  selectedQuiz?: TQuiz;
  quizzesAttemps: {
    [id: string]: {
      answers?: { question_id?: any; answer?: any }[];
      total_time?: number;
      isUploaded?: boolean;
      lastQuestionIndex?: number;
      courseSlug?: string;
      quizSlug?: any;
      isSubmited?: boolean;
    };
  };
};

const initialValues: TInitialValues = {
  selectedQuiz: undefined,
  quizzesAttemps: {},
};

const slice = createSlice({
  name: "quizes",
  initialState: initialValues,
  reducers: {
    createAttemp: (state, action) => {
      if (!state.quizzesAttemps) {
        state.quizzesAttemps = {};
      }
      state.quizzesAttemps[action.payload.id] = {
        lastQuestionIndex: 1,
        courseSlug: action.payload.courseSlug,
        quizSlug: action.payload.quizSlug,
        isSubmited: false,
        isUploaded: false,
      };
    },
    skipAnswer: (
      state,
      { payload }: { payload: { id: any; nextQuestionIndex?: any } }
    ) => {
      state.quizzesAttemps[payload.id] = {
        ...state.quizzesAttemps[payload.id],
        lastQuestionIndex: payload.nextQuestionIndex,
      };
    },
    addAnswer: (
      state,
      {
        payload,
      }: {
        payload: {
          id: any;
          total_time?: number;
          nextQuestionIndex?: any;
          question_id?: any;
          answer?: any;
          isSubmitted?: any;
        };
      }
    ) => {
      const QuestionsWhichHaveAnswer = (
        state.quizzesAttemps?.[payload.id]?.answers || []
      ).map((item) => item.question_id);
      if (QuestionsWhichHaveAnswer.includes(payload.question_id)) {
        state.quizzesAttemps[payload.id] = {
          ...state.quizzesAttemps[payload.id],
          answers: (state.quizzesAttemps?.[payload.id]?.answers || []).filter(
            (item) => item.question_id !== payload.question_id
          ),
        };
      }
      state.quizzesAttemps[payload.id] = {
        ...state.quizzesAttemps[payload.id],
        total_time: payload.total_time,
        lastQuestionIndex: payload.nextQuestionIndex,
        answers: [
          ...(state.quizzesAttemps?.[payload.id]?.answers || []),
          { question_id: payload.question_id, answer: payload.answer },
        ],
        isSubmited: payload.isSubmitted,
      };
    },
    timeChange: (
      state,
      { payload }: { payload: { id: any; total_time?: any } }
    ) => {
      state.quizzesAttemps[payload.id] = {
        ...state.quizzesAttemps[payload.id],
        total_time: payload.total_time,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetQuiz.fulfilled, (state, action) => {
      state.selectedQuiz = {
        ...action.payload.data,
        slug: action.meta.arg.quizSlug,
        courseSlug: action.meta.arg.courseSlug,
      };
    });
    builder.addCase(thunks.doGetQuizCR.fulfilled, (state, action) => {
      state.selectedQuiz = {
        ...action.payload.data,
        slug: action.meta.arg.quizId,
        courseSlug: action.meta.arg.sessionSlug,
      };
    });
    builder.addCase(thunks.doGetQuizCR.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doGetQuiz.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doSendAttemp.rejected, (state, action) => {
      toast.error(
        "make sure you have a good network to be able to send your record to the instructor"
      );
    });
    builder.addCase(thunks.doSendAttemp.fulfilled, (state, action) => {
      state.quizzesAttemps[action.meta.arg?.id] = {
        ...state.quizzesAttemps[action.meta.arg?.id],
        isUploaded: true,
      };
      toast.success("Your Record is sent to the instructor");
    });

    builder.addCase(thunks.doSendAttemp_CR.fulfilled, (state, action) => {
      state.quizzesAttemps[action.meta.arg?.id] = {
        ...state.quizzesAttemps[action.meta.arg?.id],
        isUploaded: true,
      };
      toast.success("Your Record is sent to the instructor");
    });
    builder.addCase(thunks.doSendAttemp_CR.rejected, (state, action) => {
      console.log(action);

      toast.error(
        "make sure you have a good network to be able to send your record to the instructor"
      );
    });
  },
});

const Quizes = {
  thunks,
  slice,
  createAttemp: slice.actions.createAttemp,
  addAnswer: slice.actions.addAnswer,
  skipAnswer: slice.actions.skipAnswer,
  timeChange: slice.actions.timeChange,
};

export const selectSelectedQuiz = (state: RootState) =>
  state.quizes.selectedQuiz;
export const selectQuizzesAttemps = (state: RootState) =>
  state.quizes.quizzesAttemps;
export default Quizes;
