import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { TCloudRooms, TSession } from "./model";
import thunks from "./thunks";

type TinitialValues = {
  cloudRooms?: TCloudRooms[];
  singleCloudRoom?: TCloudRooms;
  singleSession?: TSession;
  sessions: {
    [cloudRoomSlug: string]: {
      [slug: string]: TSession[];
    };
  };
  enrolledCloudRoomData: any;
};
const initialState: TinitialValues = {
  cloudRooms: [],
  singleSession: undefined,
  singleCloudRoom: undefined,
  sessions: {},
  enrolledCloudRoomData: {},
};
const slice = createSlice({
  name: "cloudRooms",
  initialState: initialState,
  reducers: {
    setEnrolledCRData: (state, action) => {
      if (!state.enrolledCloudRoomData) {
        state.enrolledCloudRoomData = {};
      }
      state.enrolledCloudRoomData[action.payload.slug] = {
        list: action.payload.list,
        type: action.payload.type,
        id: action.payload.id,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetCloudRooms.fulfilled, (state, action) => {
      state.cloudRooms = action.payload.data.data;
    });
    builder.addCase(thunks.doGetCloudRooms.rejected, (state, action) => {
      // console.log(action, "err");
    });
    builder.addCase(thunks.doGetSingleCloudRoom.fulfilled, (state, action) => {
      // console.log(action, "success");
      state.singleCloudRoom = action.payload;
    });
    builder.addCase(thunks.doGetSingleCloudRoom.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(thunks.doGetSingleSession.fulfilled, (state, action) => {
      console.log(action);

      const { sessionSlug, cloudRoomSlug } = action.meta.arg;
      if (!state.sessions) {
        state.sessions = {};
      }
      if (!state.sessions[cloudRoomSlug]) {
        state.sessions[cloudRoomSlug] = {};
      }
      state.sessions[cloudRoomSlug][sessionSlug] = action.payload;
    });
    builder.addCase(thunks.doGetSingleSession.rejected, (state, action) => {
      console.log(action);
    });
  },
});
const CloudRoom = {
  slice,
  thunks,
  setEnrolledCRData: slice.actions.setEnrolledCRData,
};

export const selectCLoudRooms = (state: RootState) =>
  state.cloudRooms.cloudRooms;
export const selectSingleCLoudRoom = (state: RootState) =>
  state.cloudRooms.singleCloudRoom;
export const selectSingleSession = (state: RootState) =>
  state.cloudRooms.singleSession;
export const selectCloudRoomSessions = (state: RootState) =>
  state.cloudRooms.sessions;
export const selectEnrolledCRData = (state: RootState) =>
  state.cloudRooms.enrolledCloudRoomData;
export default CloudRoom;
