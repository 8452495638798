import axios from "axios";

const getCartItems = () => axios.get(`/api/cart/`);
const addToCart = (buyable_id: number) =>
  axios.post(`/api/cart?buyable_id=${buyable_id}`);
const payWithCode = (code: any) => axios.post(`/api/gateways/pay/code`, code);

const CartApi = { getCartItems, payWithCode, addToCart };

export default CartApi;
